import { QueryClient } from '@tanstack/react-query';
import { createRootRouteWithContext, Outlet } from '@tanstack/react-router';
import { z } from 'zod';

import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import { initializeI18n } from '$/components/core/Localization/18n';
import { useLocalizationStore } from '$/components/core/Localization/hooks/useLocalizationStore';
import { enablePiwik, setPageViewInformation } from '$/logger';
import { LoginPage } from '$/pages/Authentication/pages/LoginPage';
import { ErrorPage } from '$/pages/ErrorPage';
import { NotFoundPage } from '$/pages/NotFoundPage';
import { validateToken } from '$/services/usecases/authentication';
import { projectsQuery } from '$/services/usecases/projects/queries';
import { useFavoriteStore } from '$/stores/useFavoriteStore';
import { usePiwikStore } from '$/stores/usePiwikStore';
import { useProjectStore } from '$/stores/useProjectStore';

interface RouterContext {
  client: QueryClient;
}

const searchParameterToRemoveAfterUsage = ['brand', 'register'];

const globalSearchSchema = z.object({
  keyMode: z.boolean().optional(),
  brand: z.string().optional(),
  register: z.boolean().optional(),
});

export const RootRoute = createRootRouteWithContext<RouterContext>()({
  notFoundComponent: NotFoundPage,
  beforeLoad: async ({ location, context: { client }, search }) => {
    if (search.brand) {
      useLocalizationStore.setState({ brand: search.brand });
    }

    if (search.register) {
      useAuthenticationStore.getState().onModalOpen('register');
    }

    await validateToken();
    await initializeI18n(search);

    const { user } = useAuthenticationStore.getState();
    let projectCount: number | null = null;

    if (user?.id != null) {
      const data = await client.ensureQueryData(projectsQuery());
      projectCount = data?.response.payload.length ?? 0;
      useProjectStore.setState({ projects: data?.response.payload ?? [] });

      await useFavoriteStore.getState().getFavorites();
    }

    enablePiwik();

    usePiwikStore.setState({
      design: null,
      designName: null,
      designOrigin: null,
    });

    setPageViewInformation(location.pathname, projectCount);
  },
  component: () => {
    const isAuthenticated = localStorage.getItem('isAuthenticated');
    const username = import.meta.env.VITE_LOGIN_USER;
    const password = import.meta.env.VITE_LOGIN_PASSWORD;

    const loginEnabled =
      (username?.length ?? 0) > 0 && (password?.length ?? 0) > 0;

    if (loginEnabled && !isAuthenticated) {
      return <LoginPage />;
    }

    return <Outlet />;
  },
  errorComponent: ErrorPage,
  pendingComponent: () => null,
  validateSearch: globalSearchSchema,
  search: {
    middlewares: [
      ({ search, next }) =>
        Object.fromEntries(
          Object.entries(next(search)).filter(
            ([k]) => !searchParameterToRemoveAfterUsage.includes(k),
          ),
        ),
    ],
  },
});
