import { changeLanguage, LanguageDetectorAsyncModule, use } from 'i18next';
import Backend, { HttpBackendOptions } from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import {
  globalCountryId,
  useLocalizationStore,
} from '$/components/core/Localization/hooks/useLocalizationStore';
import { getGeolocation } from '$/services/usecases/azure';
import { updateCountry } from '$/services/usecases/profile';

type I18nCache = {
  language: string;
  country: string;
};

const languageDetectorConfig = {
  localStorageKey: 'i18n',
};

const mapCountryToLanguage = (country: string) => {
  switch (country) {
    case 'de':
    case 'at':
    case 'ch':
      return 'de';
    case 'it':
      return 'it';
    default:
      return 'en';
  }
};

export const detectLanguageAndCountry = async (): Promise<I18nCache> => {
  const { localStorageKey } = languageDetectorConfig;
  let detected: I18nCache = { language: 'en', country: globalCountryId };

  const { region, language } = new Intl.Locale(navigator.language);
  detected.language = language;
  detected.country = region?.toLowerCase() ?? globalCountryId;

  const user = useAuthenticationStore.getState().user;
  const localStorageItem = localStorage.getItem(localStorageKey);
  if (localStorageItem) {
    detected = JSON.parse(localStorageItem) as I18nCache;
  } else if (user?.country != null) {
    detected.country = user.country;
    detected.language = mapCountryToLanguage(user.country);
  } else {
    try {
      const ip = await getGeolocation();
      const ipIsoCode = ip.response.payload.isoCode;

      // https://www.iso.org/obp/ui/#search
      if (ipIsoCode) {
        detected.country = ipIsoCode.toLowerCase();
        detected.language = mapCountryToLanguage(detected.country);
      }
    } catch (error) {
      console.error(error);
    }
  }

  if (user && (user.country !== detected.country || !detected.country)) {
    void updateCountry(detected.country).catch(() => {});
  }

  const search = new URLSearchParams(window.location.search);
  if (search.has('brand')) {
    detected.country = search.get('brand')!;
  }

  return detected;
};

export const persistLocalization = (payload: I18nCache) => {
  const { localStorageKey } = languageDetectorConfig;
  localStorage.setItem(localStorageKey, JSON.stringify(payload));
};

const languageDetector: LanguageDetectorAsyncModule = {
  type: 'languageDetector',
  async: true,
  detect: async () => {
    const detected = await detectLanguageAndCountry();
    persistLocalization(detected);
    useLocalizationStore.setState(detected);
    return detected.language;
  },
};

export const i18n = use(initReactI18next).use(languageDetector).use(Backend);
export const t = i18n.t.bind(i18n);

export const initializeI18n = async ({ keyMode }: { keyMode?: boolean }) => {
  if (!i18n.isInitialized) {
    await i18n.init<HttpBackendOptions>({
      debug: import.meta.env.VITE_NODE_ENV !== 'production',
      fallbackLng: 'en',
      backend: {
        loadPath: `${import.meta.env.VITE_LANGUAGE_STORAGE_URL}{{lng}}.json`,
        parse: (data) => {
          const parsedData = JSON.parse(data) as Record<string, unknown>;

          if (parsedData.translation != null) {
            return parsedData.translation;
          }

          return parsedData;
        },
      },
    });

    if (i18n.language === 'de') {
      await import('dayjs/locale/de');
    } else {
      await import('dayjs/locale/en');
    }
  }

  if (keyMode != null) {
    localStorage.setItem('translationKeyMode', keyMode.toString());
  }

  const isTranslationKeyMode =
    keyMode ?? localStorage.getItem('translationKeyMode') === 'true';

  if (isTranslationKeyMode) {
    await changeLanguage('cimode');
  }
};
